import { DateTime } from 'luxon';
export var isValidDate = function isValidDate(value, options) {
  var _ref = options || {},
      _ref$format = _ref.format,
      format = _ref$format === void 0 ? 'dd/MM/yyyy' : _ref$format,
      _ref$onlyFutureDates = _ref.onlyFutureDates,
      onlyFutureDates = _ref$onlyFutureDates === void 0 ? false : _ref$onlyFutureDates;

  var date = DateTime.fromFormat(value, format);
  var filled = value && value.replace(/[^\d]+/g, '').length === 8;
  var validPattern = filled && date.isValid;

  if (validPattern && onlyFutureDates) {
    validPattern = DateTime.local() < date;
  }

  return validPattern;
};