import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    width: ", ";\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n    line-height: ", ";\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    border: 0;\n    clip: rect(0, 0, 0, 0);\n    height: 1px;\n    margin: -1px;\n    overflow: hidden;\n    padding: 0;\n    position: absolute;\n    width: 1px;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n          transform: translate(-50%, -50%);\n        "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n          margin-top: ", ";\n          margin-left: ", ";\n        "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    position: absolute;\n    top: 50%;\n    left: 50%;\n\n    ", "\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    /* prettier-ignore */\n    background-image: url(\"", "\");\n    background-repeat: no-repeat;\n    background-position: center center;\n    height: ", ";\n    width: ", ";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.14);\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from '@emotion/css';
import { memoize, isPlainObject, isArray, map, fromPairs, camelCase } from '@ampli/utils';
import { px2rem, getSpaceSize, getFontSize, getLineHeightSize, svgComponentToDataUri } from "./functions";
export var boxShadow = memoize(function (type) {
  if (type === 'card') {
    return css(_templateObject());
  } else {
    return css(_templateObject2());
  }
});
export var resolveCssProperty = function resolveCssProperty(property, value) {
  var resolved;

  if (isPlainObject(value)) {
    resolved = fromPairs(map(value, function (propertyValue, propertyKey) {
      return [camelCase("".concat(property, "-").concat(propertyKey)), getSpaceSize(propertyValue)];
    }));
  } else if (isArray(value)) {
    resolved = _defineProperty({}, property, map(value, function (propertyValue) {
      return getSpaceSize(propertyValue);
    }).join(' '));
  } else {
    resolved = _defineProperty({}, property, getSpaceSize(value));
  }

  return css(resolved);
};
export var iconBackground = function iconBackground(Icon, options) {
  var _ref = options || {},
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? '16px' : _ref$size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'text-light' : _ref$color;

  var resolvedSize = px2rem(size);
  return css(_templateObject3(), svgComponentToDataUri(Icon, {
    currentColor: color
  }), resolvedSize, resolvedSize);
};
export var centralized = function centralized(options) {
  var _ref2 = options || {},
      size = _ref2.size;

  var cssSize = size && "calc(-".concat(size, " / 2)");
  return css(_templateObject4(), cssSize ? css(_templateObject5(), cssSize, cssSize) : css(_templateObject6()));
};
export var hideText = memoize(function () {
  return css(_templateObject7());
});
export var fontSize = memoize(function () {
  var size = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'medium';
  return css(_templateObject8(), getFontSize(size), getLineHeightSize(size));
});
export var ellipsis = memoize(function (size) {
  return css(_templateObject9(), size);
});