import React from 'react';
import { Admin, Resource } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import {
  clientCreator,
  i18nProviderCreator,
  dataProviderCreator,
  authProviderCreator,
  LoginPage,
  themeCreator,
} from '@ampli/admin-core';

import * as resources from './resources';
import { ampli } from './config';
import {
  LeadCreate,
  LeadEdit,
  LeadShow,
  LeadList,
  AffiliateCreate,
  AffiliateEdit,
  AffiliateList,
  SellerCreate,
  SellerEdit,
  SellerList,
  SellerShow,
  SellerUnitCreate,
  SellerUnitEdit,
  SellerUnitList,
  SellerUnitShow,
  SellerRegionalCreate,
  SellerRegionalEdit,
  SellerRegionalList,
  SellerRegionalShow,
  SellerCategoryCreate,
  SellerCategoryEdit,
  SellerCategoryList,
  SellerCategoryShow,
  CouponCreate,
  CouponEdit,
  CouponList,
  CouponShow,
  CandidateList,
  CandidateShow,
  CandidateEdit,
} from './views';
import possibleTypes from './fragment-types.json';

const client = clientCreator({
  ...ampli,
  cacheSettings: {
    possibleTypes,
  },
});
const authProvider = authProviderCreator({ client });
const dataProvider = dataProviderCreator({ client, resources });
const i18nProvider = i18nProviderCreator();
const theme = themeCreator();

export const App = () => {
  return (
    <Admin
      theme={theme}
      history={createHistory()}
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
    >
      {/* Do not remove this resources cause they are being used as Reference Field */}
      <Resource name="course" />
      <Resource name="coursePrice" />
      <Resource name="city" />
      <Resource name="state" />
      <Resource
        name="lead"
        list={LeadList}
        create={LeadCreate}
        show={LeadShow}
        edit={LeadEdit}
        options={{ label: 'Leads' }}
      />
      <Resource
        name="candidate"
        list={CandidateList}
        show={CandidateShow}
        edit={CandidateEdit}
        options={{ label: 'Candidatos' }}
      />
      <Resource
        name="affiliate"
        create={AffiliateCreate}
        list={AffiliateList}
        edit={AffiliateEdit}
        options={{ label: 'Núcleos de Apoio' }}
      />
      <Resource
        name="sellerRegional"
        list={SellerRegionalList}
        create={SellerRegionalCreate}
        show={SellerRegionalShow}
        edit={SellerRegionalEdit}
        options={{ label: 'Regional dos Vendedores' }}
      />
      <Resource
        name="sellerUnit"
        list={SellerUnitList}
        create={SellerUnitCreate}
        show={SellerUnitShow}
        edit={SellerUnitEdit}
        options={{ label: 'Unidade dos Vendedores' }}
      />
      <Resource
        name="sellerCategory"
        list={SellerCategoryList}
        create={SellerCategoryCreate}
        show={SellerCategoryShow}
        edit={SellerCategoryEdit}
        options={{ label: 'Categoria dos Vendedores' }}
      />
      <Resource
        name="seller"
        list={SellerList}
        create={SellerCreate}
        show={SellerShow}
        edit={SellerEdit}
        options={{ label: 'Vendedores' }}
      />
      <Resource
        name="coupon"
        list={CouponList}
        create={CouponCreate}
        show={CouponShow}
        edit={CouponEdit}
        options={{ label: 'Cupons' }}
      />
    </Admin>
  );
};
