import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _typeof from "@babel/runtime/helpers/typeof";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { trim, isString, size } from 'lodash';
import { DateTime, Duration } from 'luxon';
export var phoneFormatter = function phoneFormatter(value) {
  if (!isString(value)) {
    throw new Error('Phone should be string, but get: ' + _typeof(value));
  }

  var phone = value.replace(/[^\d]+/g, '').split('');

  if (size(phone) < 11) {
    throw new Error('Invalid phone size: ' + size(phone));
  }

  phone.splice(0, 0, '(');
  phone.splice(3, 0, ')');
  phone.splice(4, 0, ' ');
  phone.splice(-4, 0, '-');
  return phone.join('');
};
export var boletoCodeFormatter = function boletoCodeFormatter(value) {
  if (!isString(value)) {
    throw new Error('Boleto code should be string, but get: ' + _typeof(value));
  }

  var boleto = trim(value).replace(/[^\d]+/g, '').split('');

  if (size(boleto) < 47) {
    throw new Error('Invalid boleto code size: ' + size(boleto));
  }

  for (var i = 0; i < 30; i += 10) {
    boleto[i + 4] += '.';
    boleto[i + 9] += ' ';
  }

  boleto.splice(-16, 0, ' ');
  return boleto.join('');
};
export var numberFormatter = function numberFormatter(value) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var _settings$language = settings.language,
      language = _settings$language === void 0 ? 'pt-BR' : _settings$language,
      options = _objectWithoutProperties(settings, ["language"]);

  var formatter = new Intl.NumberFormat(language, options);
  return formatter.format(value || 0);
};
export var moneyFormatter = function moneyFormatter(value) {
  var settings = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _settings$currency = settings.currency,
      currency = _settings$currency === void 0 ? 'BRL' : _settings$currency;
  return numberFormatter(value, _objectSpread({
    style: 'currency',
    currency: currency
  }, settings));
};
export var addressFormatter = function addressFormatter(address) {
  return [address.street, address.number, address.complement, address.neighbourhood, address.cityName, address.stateAcronym].filter(function (a) {
    return a;
  }).join(', ');
};
export var formatIsoDate = function formatIsoDate(isoDate) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'dd/MM/yyyy';
  return DateTime.fromISO(isoDate).toFormat(format);
};
export var formatIsoToLocaleDate = function formatIsoToLocaleDate(isoDate) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DATE_SHORT';
  return DateTime.fromISO(isoDate).toLocaleString(DateTime[format] || format);
};
export var formatDuration = function formatDuration(startDate, endDate) {
  var format = 'dd/MM';
  return "".concat(formatIsoDate(startDate, format), " - ").concat(formatIsoDate(endDate, format));
};
export var formatTimeSeconds = function formatTimeSeconds(seconds) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'mm:ss';
  return seconds ? Duration.fromObject({
    seconds: seconds
  }).toFormat(format) : '00:00';
};