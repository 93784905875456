import { uniqueId } from '@ampli/utils';
export var dataProviderCreator = function dataProviderCreator(_ref) {
  var client = _ref.client,
      resources = _ref.resources;
  return function (type, resource, params) {
    var queryId = uniqueId("".concat(resource, " ").concat(type, " "));

    try {
      console.info(queryId, {
        params: params
      });
      return resources[resource][type]({
        client: client,
        params: params,
        queryId: queryId
      }).then(function (response) {
        console.debug(queryId, {
          response: response
        });
        return response;
      }).catch(function (error) {
        console.debug(queryId, {
          error: error
        });
        throw error;
      });
    } catch (error) {
      console.debug(queryId, {
        error: error
      });
      throw new Error("Unsupported provider: ".concat(resource, ":").concat(type));
    }
  };
};