import React from 'react';
import ReactDOM from 'react-dom';
import { injectRootStyles } from '@ampli/admin-core';

import './lib/logger';
import { App } from './app';

injectRootStyles();

ReactDOM.render(<App />, document.getElementById('root'));
