import { DateTime } from '@ampli/utils';
import React from 'react';
import {
  Create,
  DateInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { data, validation } from './common';

export const CouponCreate = (props) => (
  <Create title="Vendedor - Create" {...props}>
    <SimpleForm redirect="list">
      <TextInput label="Código" source="code" validate={validation.code} />
      <TextInput
        label="Descrição"
        source="description"
        validate={validation.description}
      />
      <SelectInput
        label="Tipo"
        source="type"
        choices={data.types}
        validate={validation.type}
      />
      <NumberInput
        label="Desconto"
        source="discount"
        validate={validation.discount}
      />
      <NumberInput
        label="Quantidate de cupons disponíveis"
        source="initialAmount"
        validate={validation.initialAmount}
      />
      <DateInput
        label="Data de Início"
        source="startDate"
        defaultValue={DateTime.local()}
        validate={validation.startDate}
      />
      <DateInput
        label="Data de Expiração"
        source="expirationDate"
        validate={validation.expirationDate}
      />
      <NumberInput
        label="Quantidate de parcelas"
        source="numberOfInstalments"
        validate={validation.initialAmount}
        defaultValue={1}
      />
    </SimpleForm>
  </Create>
);
