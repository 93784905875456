import { forEach } from 'lodash';
export var loadScript = function loadScript(src) {
  var attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return new Promise(function (resolve, reject) {
    var script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);
    forEach(attributes, function (value, key) {
      return script.setAttribute(key, value);
    });
    document.body.appendChild(script);
  });
};