import * as workerTimers from 'worker-timers'; // the resolution parameter add precision
// see: https://thecodersblog.com/increase-javascript-timoeout-accuracy/

export var setExactInterval = function setExactInterval(callback, delay) {
  var resolution = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 100;
  var start = new Date().getTime();
  return workerTimers.setInterval(function () {
    if (new Date().getTime() - start >= delay) {
      callback();
    }
  }, resolution);
};
export var clearExactInterval = function clearExactInterval(interval) {
  workerTimers.clearInterval(interval);
};
export var setExactTimeout = function setExactTimeout(callback) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  var interval = setExactInterval.apply(void 0, [function () {
    callback();
    clearExactInterval(interval);
  }].concat(args));
};
export var clearExactTimeout = function clearExactTimeout(interval) {
  clearExactInterval(interval);
};