import localForage from 'localforage';
import { getGlobal } from "./global";
export var authStorage = localForage.createInstance({
  name: 'hipotenusa',
  storeName: 'auth'
});
export var dataStorage = localForage.createInstance({
  name: 'hipotenusa',
  storeName: 'data'
});
export var localStorage = getGlobal().localStorage || {};