export { name, version } from '../../package.json';

export const ampli =
  window.__AMPLI__ || JSON.parse(process.env.REACT_APP_AMPLI);

export const isProduction = ampli.env === 'production';

export const logLevel = isProduction ? 'error' : 'debug';

export const sentryDsn =
  'https://df1dc86b7a684e8ab7ffbf49b0354185@sentry.io/5187952';
