require('intersection-observer');

require('share-api-polyfill');

require('fullscreen-polyfill');
/**
 *  Polyfill SVG path support for iOS
 *  see: https://gist.github.com/rickbutterfield/96427c4d1f36d659397dc6a1b8557010
 */


SVGCircleElement.prototype.getTotalLength = function () {
  var width = this.parentNode.clientWidth;
  var radius = width / 2;
  return 2 * Math.PI * radius;
};