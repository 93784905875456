import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\nhtml,\nbody {\n  height: 100%;\n}\n\nbody {\n  background-color: ", ";\n}\n\n#root {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: auto;\n  min-height: 100%;\n}\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { injectGlobal, ColorNeutralBackground } from '@ampli/ui';
import '@ampli/ui/dist/global-styles';
export var injectRootStyles = function injectRootStyles() {
  injectGlobal(_templateObject(), ColorNeutralBackground);
};