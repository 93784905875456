import confetti from 'canvas-confetti';
import { isMobile } from "../../utils";
var launchConfetti;

if (typeof window === 'undefined') {
  launchConfetti = function launchConfetti() {};
} else {
  launchConfetti = function launchConfetti() {
    var colors = ['#B9F044', '#FAC6CF', '#CCF0F6', '#D1F4DC', '#87C6A8', '#E2AF54'];

    if (isMobile()) {
      var end = Date.now() + 1100;

      (function frame() {
        confetti({
          particleCount: 6,
          angle: 70,
          spread: 55,
          origin: {
            x: -0.6
          },
          colors: colors
        });
        confetti({
          particleCount: 6,
          angle: 110,
          spread: 55,
          origin: {
            x: 1.6
          },
          colors: colors
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    } else {
      var _end = Date.now() + 1000;

      (function frame() {
        confetti({
          particleCount: 6,
          angle: 60,
          spread: 55,
          origin: {
            x: -0.1
          },
          colors: colors
        });
        confetti({
          particleCount: 6,
          angle: 120,
          spread: 55,
          origin: {
            x: 1.1
          },
          colors: colors
        });

        if (Date.now() < _end) {
          requestAnimationFrame(frame);
        }
      })();
    }
  };
}

export default launchConfetti;