import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { inspect } from 'util';
import * as Sentry from '@sentry/browser';
var levels = {
  error: 0,
  warn: 1,
  info: 2,
  verbose: 3,
  debug: 4,
  silly: 5
};
var termColors = {
  red: '31',
  green: '32',
  olive: '33',
  blue: '34'
};
var originalConsole = {
  log: console.log
};
Object.keys(levels).forEach(function (level) {
  if (console[level]) {
    originalConsole[level] = console[level];
  }
});

var createLogger = function createLogger() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var appName = options.appName,
      _options$logLevel = options.logLevel,
      logLevel = _options$logLevel === void 0 ? 'info' : _options$logLevel,
      _options$debugMode = options.debugMode,
      debugMode = _options$debugMode === void 0 ? false : _options$debugMode,
      _options$isNode = options.isNode,
      isNode = _options$isNode === void 0 ? true : _options$isNode,
      _options$inspectOptio = options.inspectOptions,
      inspectOptions = _options$inspectOptio === void 0 ? {} : _options$inspectOptio,
      _options$sentrySettin = options.sentrySettings,
      sentrySettings = _options$sentrySettin === void 0 ? null : _options$sentrySettin;

  if (sentrySettings) {
    Sentry.init(_objectSpread(_objectSpread({}, sentrySettings), {}, {
      ignoreErrors: [/^Warning: /, 'UNAUTHENTICATED', 'Authentication failed', /^Network error: /, /^BAD_USER_INPUT: /].concat(_toConsumableArray(sentrySettings.ignoreErrors || [])),
      allowUrls: [/^https:\/\/(.+\.)?ampli\.com\.br/i].concat(_toConsumableArray(sentrySettings.allowUrls || []))
    }));
  }

  var color = function color(_color, str) {
    if (!debugMode) return [str];

    if (isNode) {
      return ["\x1B[".concat(termColors[_color], "m").concat(str, "\x1B[0m")];
    }

    return ["%c".concat(str), "color: ".concat(_color, ";")];
  };

  var colors = {};
  Object.keys(termColors).forEach(function (colorName) {
    colors[colorName] = function (str) {
      return color(colorName, str);
    };
  });
  var levelColor = {
    error: colors.red,
    warn: colors.olive,
    info: colors.blue,
    debug: colors.green,
    silly: function silly() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return args;
    }
  };
  inspectOptions = _objectSpread({
    colors: debugMode
  }, inspectOptions);
  logLevel = logLevel.toLowerCase();

  var prepareArgs = function prepareArgs(level) {
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }

    args = args.map(function (a) {
      return a === Object(a) && isNode ? debugMode ? inspect(a, inspectOptions) : JSON.stringify(a) : a;
    });
    args.unshift(level);
    !debugMode && args.unshift(new Date().toISOString().replace(/[TZ]/g, ' '));
    return args;
  };

  var log = function log(logFunc) {
    var log = originalConsole[logFunc] || originalConsole.log;

    for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
      args[_key3 - 1] = arguments[_key3];
    }

    log && log.apply(void 0, _toConsumableArray(prepareArgs.apply(void 0, args)));

    if (logFunc === 'error' && sentrySettings) {
      var errorIndex = debugMode && !isNode ? 2 : 1;
      var error = args[errorIndex] || 'UNKNOWN ERROR';

      if (!(error instanceof Error)) {
        error = new Error(error.message || error);
      }

      Sentry.withScope(function (scope) {
        if (error.info) {
          scope.setExtras(error.info);
        }

        scope.setTag('app.name', appName);
        Sentry.captureException(error);
      });
    }
  };

  var logger = {};
  Object.keys(levels).forEach(function (level) {
    logger[level] = function () {
      if (levels[logLevel] >= levels[level]) {
        for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
          args[_key4] = arguments[_key4];
        }

        log.apply(void 0, [level].concat(_toConsumableArray(levelColor[level](level.toUpperCase())), args));
      }
    };
  });
  return logger;
};

export var hijackConsole = function hijackConsole() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var logger = createLogger(options);
  Object.keys(levels).forEach(function (level) {
    console[level] = logger[level];
  });
  return console;
};
export default createLogger;